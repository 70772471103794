<template>
  <v-dialog
    v-model="dailog"
    width="870"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left>
          mdi-account-edit
        </v-icon>Booking Details
        <v-spacer />
        <v-btn
          color="success"
          text
          dense
          @click="emitToParent('close')"
        >
          Close
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form>
          <v-row>
            <v-col
              cols="6"
            >
            <v-form  
              
              ref="form"
              v-model="valid"
              lazy-validation
            >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
            <span class="font-weight-bold text-h6">
              REF. NO: {{booking.ref_no}}
            </span>
            
            <v-divider class="mb-4"/>
              <!-- <v-autocomplete
                v-model="booking.class"
                :rules="nameRules"
                :items="['DOMESTIC', 'FOREIGN']"
                label="Classification"
                dense
                outlined
              /> -->
              <!-- Room Details:
              <v-divider class="mb-4"/> -->
             
              <!-- <v-text-field
              dense
                outlined
                label="No. of rooms occupied"
                type="number"
              />
              <v-text-field
              dense
                outlined
                hint="Ex. 101,102"
                label="Booked room numbers"
              /> -->
              <v-row>
                <v-col cols="6">
                  <v-menu
                        v-model="from_menu"
                        :rules="nameRules"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Checkin Date"
                                 :value="booking.checkin_date"
                                 :rules="nameRules"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="booking.checkin_date"   no-title scrollable @input="from_menu=false" />
                    </v-menu>

                </v-col>
                <v-col cols="6">

                  <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Checkout Date"
                                 :value="booking.checkout_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="booking.checkout_date" no-title scrollable @input="to_menu=false" />
                    </v-menu>
                </v-col>
              </v-row>

              <v-text-field
                v-model="booking.no_of_guest"
                :rules="nameRules"
                dense
                outlined
                label="No. of guest"
                type="number"
              />
              <v-checkbox
              v-model="booking.with_infant"
              label="Traveling with infants (0-5 yrs old)"
              color="success"
              value="yes"
            ></v-checkbox>

              Primary Contact:
              <v-divider class="mb-4"/>

              <v-text-field
               v-model="booking.contact_person.firstname"
                :rules="nameRules"  
              dense
                outlined
                label="First Name"
              />
              <v-text-field
               v-model="booking.contact_person.lastname"
                :rules="nameRules"  
              dense
                outlined
                label="Last Name"
              />
              <v-text-field
              v-model="booking.contact_person.number"
                :rules="nameRules"  
              dense
                outlined
                label="Contact No."
              />
              <v-text-field
              v-model="booking.contact_person.email"
              dense
                outlined
                label="Email Address"
              />

              <v-autocomplete
                v-model="booking.status"
                :rules="nameRules"
                :items="['Booked', 'Cancelled']"
                label="Status"
                dense
                outlined
              />
            
            </v-col>

            <v-row no-gutters>
                <v-col cols="12">
                  Send Booking REF. NO
                  <v-divider/>
                </v-col>
                <v-col cols="6" class="pa-2">
                    <v-btn large block color="primary" disabled>via SMS</v-btn>
                </v-col>
                <v-col cols="6" class="pa-2">
                    <v-btn large block color="primary" :loading="eloading" @click="eloading = true, emailBookingRef()">via Email</v-btn>
                </v-col>
            </v-row>
            
          </v-row>
        </v-form>
            </v-col>
            <v-col cols="6">
              Guest Form:
              <v-divider class="mb-4"/>
              <v-form  
              ref="form2"
              v-model="valid2"
              lazy-validation
            >
              <v-card>
                <v-card-text>
                 
                  <v-row>
                  
                    <v-col cols="6">
                      <v-text-field
                      :rules="nameRules"
                      v-model="guest.firstname"
                      dense
                      :disabled="guests.length >=booking.no_of_guest"
                        label="First Name"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                      dense
                      :rules="nameRules"
                        label="Last Name"
                        :disabled="guests.length >=booking.no_of_guest"
                        v-model="guest.lastname"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      v-model="guest.gender"
                      :disabled="guests.length >=booking.no_of_guest"
                      :items="['Male', 'Female']"
                      label="Gender"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                    dense
                    label="Age"
                    :disabled="guests.length >=booking.no_of_guest"
                    v-model="guest.age"
                    type="number"
                  />
                    </v-col>  
                    
                    <v-col cols="6">
                      <v-autocomplete
                          v-model="guest.class"
                          :disabled="guests.length >=booking.no_of_guest"
                          :rules="nameRules"
                          :items="['DOMESTIC', 'FOREIGN', 'OFW']"
                          label="Classification"
                          @change="setCountry()"
                          dense
              />  
                    </v-col>
                  <v-col cols="6">
                      <v-autocomplete
                      :disabled="guests.length >=booking.no_of_guest"
                      :rules="nameRules"
                      :items="countries"
                      v-model="guest.country"
                      label="Country"
                      item-value="name"
                      item-text="name"
                      
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      v-if="guest.class=='DOMESTIC'"
                      :disabled="guests.length >=booking.no_of_guest"
                      :items="countries"
                      v-model="guest.region"
                      label="Region"
                      item-value="name"
                      item-text="name"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                    v-if="guest.class=='DOMESTIC'"
                    v-model="guest.province"
                    :disabled="guests.length >=booking.no_of_guest"
                    dense
                    label="State/Province"
                  />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                    v-if="guest.class=='DOMESTIC'"
                    v-model="guest.city"
                    :disabled="guests.length >=booking.no_of_guest"
                    dense
                    label="City/Municipality"
                  />
                 
                </v-col>
                  </v-row>
                

    
                </v-card-text>
                <v-card-actions>
                    <v-btn small tile :disabled="guests.length >=booking.no_of_guest"  @click="clearForm()">Clear</v-btn>
                    <v-spacer/>
                    <v-btn small tile color="info" :loading="gloading" @click="validateGuest()" :disabled="guests.length >=booking.no_of_guest">Add</v-btn>
                </v-card-actions>
              </v-card>
             </v-form>
              <div class="mt-10">({{guests.length}})Guests :</div> 
              <v-divider class="mb-1"/>
             <v-list lines="one"  id="va-guest-list">
              <template  v-for="(item,index) in guests">
                  <v-list-item
                 
                    :key="index"
                  >
                  <v-icon left color="error" small @click="removeGuest(item)">mdi-close</v-icon>
                    {{item.firstname}}  {{item.lastname}} ({{item.age}}) - {{item.country}}
                   
                </v-list-item>
                
                </template>  
                </v-list>
            </v-col>
          </v-row>
        </v-form>
        <v-divider class="mt-4"/>
      </v-card-text>
      <v-card-actions>
       
              
              <v-spacer/>
              <v-btn    @click="emitToParent('close')">Close</v-btn>
              <v-btn   color="success" :loading="loading" @click="saveBooking">Save </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () =>{}
    }
    
    },
    data: () => ({
        valid: true,
        valid2: true,
        loading: false,
        gloading: false,
        eloading: false,
        dailog: false,
        from_menu: false,
        to_menu: false,
        guests: [],
        guest:{},
        booking:{contact_person:{}},
        from_date: "",
        to_date: "",
        nameRules: [
        v => !!v || 'Field is required'
      ],
      guestRules: [
        v => !!v || 'Field is required'
      ],
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.booking = this.data
            this.guestList()
          } else {
            this.loading = false
            this.gloading = false
            this.eloading = false
            this.booking = {contact_person:{}}
          }
        }
    },
    created() {
    },
    computed: {
      countries() {
        return this.$countries
      }
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      setCountry(){
        //console.log(this.guests.class)
        if(this.guest.class == "DOMESTIC") {
          this.guest.country = 'Philippines'
        } else {
          this.guest.country = ""
        }
      
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.saveBooking()
        }
      },
      validateGuest() {
        if (this.$refs.form2.validate()) {
          this.addGuest()
        }
      },
      emailBookingRef(){
        console.log( {booking_no:this.booking.ref_no, recipient: this.booking.contact_person.email})
        this.$http.post('email/booking', {booking_no:this.booking.ref_no, recipient: this.booking.contact_person.email}).then(response => {
          this.eloading = false
          response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
        }).catch(e => {
        console.log(e)
        })
      },
      saveBooking() {
        this.booking.no_of_guest =  this.$IsNum(this.booking.no_of_guest)
        this.booking.contact_person.lastname = this.$Capitalize(this.booking.contact_person.lastname)
        this.booking.contact_person.firstname = this.$Capitalize(this.booking.contact_person.firstname)
        console.log(this.booking.contact_person.firstname)
       this.$http.post('booking/update', this.booking).then(response => {
            this.loading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
         
        }).catch(e => {
        console.log(e)
        })
      },
      guestList() {
        this.$http.post('booking/guests', {booking_id:this.$IsNum(this.booking.id)}).then(response => {
            response.data.guests? this.guests = response.data.guests:this.guests = []
            console.log(response.data)
        }).catch(e => {
        console.log(e)
        })
      }, 
      clearForm(){
        this.$refs.form2.reset()
        this.guest = {}
        
      },
      addGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.guest.booking_id = this.$IsNum(this.booking.id)
        this.guest.hotel_id = this.$IsNum(this.booking.hotel_id)
        this.guest.ref_no = this.booking.ref_no
        this.$http.post('guest/add', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           // this.clearForm()
            this.guest.firstname = ""
            this.guest.lastname = ""
            this.guest.age = ""
            this.guest.gender = ""
            this.valid2 = true
            this.guestList() 
        }).catch(e => {
        console.log(e)
        })
      },
      removeGuest(guest) {
       console.log(guest)
        this.$http.post('guest/remove', guest).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.guestList() 
          
        }).catch(e => {
        console.log(e)
        })
      }
    },
  }
</script>
