/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/main',
      name: 'Main',
      view: 'Main'
    },
    {
      path: '/registration',
      name: 'Registration',
      view: 'RegistrationPage'
    },
    {
      path: '/verify',
      name: 'Verification',
      view: 'VerifyPage'
    },
    {
      path: '/bookings',
      name: 'Booking Management',
      view: 'BookingPage'
    },
    {
      path: '/guest-list',
      name: 'Guest List',
      view: 'GuestList'
    },
    {
      path: '/account',
      name: 'Account Management',
      view: 'AccountPage'
    },
  
  ]
  