<template>
  <v-app>
    <core-toolbar />
    <core-drawer />
    <core-view />
    <!-- <core-footer /> -->
    <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="2000"
       :top="'top'"
     >
      {{alertText }}
    </v-snackbar>
  </v-app>
</template>
<script>
import {
  mapMutations
} from 'vuex'

export default {
  name: 'App',
  
  data: () => ({
  }),
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
      overlay() {
         return this.$store.state.overlay
      },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      },

  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert']),
    reserveEVent() {
      this.setShowBooking(false)
    },
      onResponsiveInverted() {
          if (window.innerWidth < 991) {
           this.setResponsive(true)
          } else {
            this.setDrawer(true)
            this.setResponsive(false)
          }
    },
  }

};
</script>
<style>
  .welcome-block {
     color: #fff;
     margin: 70% auto 0 auto;
  }
  .text-success {
    color: #5cb860;
  }
  .text-gray {
    color: #dcdcdc;
  }
  .text-primary {
    color: #EEB06C;
  }
  .text-secondary {
    color: #FECA6E;
  }
  .text-tertiary {
    color: #FECA6E;
  }
  .va-table-border-bot th, .va-table-border-bot td {
    border-bottom: 1px solid #ddd;
  }
  #va-guest-list .v-list-item {
    min-height: 32px !important;
    padding: 0 0 !important;
  }
</style>