import Vue from 'vue'
// Lib imports
import axios from 'axios'
var production = true
Vue.prototype.$app_mode = "production"
var SERVER = " http://localhost:9181"
if(production) {
  if(Vue.prototype.$app_mode == "production")
        SERVER = "https://dev-srv.eboracay.net" 
  else if(Vue.prototype.$app_mode == "local")
        SERVER = "http://localhost:9181"
}
Vue.prototype.$http = axios.create({
  baseURL: SERVER + '/api/'
})

Vue.prototype.$fileserver = SERVER + '/public/' 