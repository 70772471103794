<template>
  <v-app-bar
    v-model="appbar"
    dense
    elevation="0"
    color="white"
    app
    class="font-weight-light"
  >
    <v-app-bar-nav-icon
      dense
      :color="bar_color"
      @click="drawer"
    />
    <v-toolbar-title class="text-primary">
      {{ $route.name }}
    </v-toolbar-title>
    <v-spacer />
    <span class="caption text-primary">Hi, {{$session.get('fullname')}}</span>
    <v-icon
      dense
      right
      color="error"
      @click="confirm = true"
    >
      mdi-logout
    </v-icon>

    
    <v-dialog
      v-model="confirm"
      persistent
      max-width="290">
      <v-card>
        <v-card-text class="pt-5">Would you like to log out?</v-card-text>
        <v-card-actions>
         
          <v-btn
            color="warning darken-1"
            text
            @click="confirm = false">Cancel</v-btn>
             <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="confirm = false, logout()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      confirm: false,
      bar_color: "secondary"
    }),
    computed: {
      appbar() {
        return this.$store.state.appbar
      }
    },
    methods: { 
      ...mapMutations(['setLoggedIn', 'setAppBar', 'setDrawer']),
      drawer() {
        this.$store.state.drawer != true?this.setDrawer(true):this.setDrawer(false)
        this.bar_color != "primary"?this.bar_color="primary":this.bar_color="secondary"
      },
      logout() {
        this.setDrawer(false)
        this.setAppBar(false)
         this.setLoggedIn(false)
         this.$session.destroy(),
         this.$router.push('/')
      }

    }
  }
</script>