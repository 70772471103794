<template>
  <v-main class="font-weight-light">
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>
<script>
export default {
  metaInfo () {
    return {
      title: 'Mapple - ' + this.$route.name
    }
  }
}
</script>